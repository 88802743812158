// Global
$ant-border: 1px solid var(--border-color);

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	color: #fff;
	background: var(--primary-color) !important;
	border-color: var(--primary-color) !important;
}

html,
body,
#root {
	font-family: var(--main-font);
}

*:focus {
	outline: none !important;
	box-shadow: none !important;
}

/* Safari-specific rule for mobile */
@media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
	.ant-modal-content div.ant-modal-body {
		max-height: calc(var(--actual-viewport-height) - 150px) !important;
	}
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.emma-modal {
	.ant-modal-header {
		background-color: var(--primary-color);
		.ant-modal-title {
			color: #fff;
			font-weight: bold;
			font-size: 20px;
		}
	}
}

.bold-field-label label,
.emma-bold {
	font-weight: bold !important;
}
.bold-field-label .no-bold-font label {
	font-weight: 500 !important;
}

.emma-red-highlight,
.emma-red-highlight .emma-highlight,
.emma-red-highlight .anticon {
	font-weight: bold;
	color: red;
}

.emma-highlight,
.stats-card .ant-card-head {
	font-weight: bold;
	color: var(--primary-color);
}
.emma-gold-color {
	color: var(--warning-color) !important;
}
.emma-color {
	color: var(--primary-color) !important;
}
.text-white {
	color: #fff !important;
}
.emma-red {
	color: red !important;
}
.emma-bg,
.ant-spin-dot-item {
	background-color: var(--primary-color) !important;
}
.emma-bg-color {
	background-color: var(--primary-color) !important;
	border-color: var(--primary-color) !important;
}
.font-16px {
	font-size: 16px;
}
.cursor-auto {
	cursor: auto !important;
}
.cursor-pointer {
	cursor: pointer !important;
}
.emma-link:hover {
	color: var(--primary-color) !important;
	text-decoration: underline;
}
.emma-link-underline {
	text-decoration: underline !important;
}

.payment-table-row-bg {
	background-color: #ededeb;
}

.lightgreen-table-row-bg {
	background-color: #ebf2f3;
	.emma-table-action {
		background-color: #ebf2f3 !important;
	}
	&:hover {
		.ant-table-cell {
			background-color: #daf2f5 !important;
		}
	}
}

.lightgray-table-row-bg {
	background-color: var(--body-bg-color);
	.emma-table-action {
		background-color: var(--body-bg-color) !important;
	}
	&:hover {
		.ant-table-cell {
			background-color: var(--body-bg-color) !important;
		}
	}
}

.striped-table-row {
	border-bottom: 4px solid #fff !important;
	.emma-table-action {
		border-bottom: 4px solid #fff !important;
	}
	.ant-table-cell {
		border-bottom: 4px solid #fff !important;
	}
	&:hover {
		.ant-table-cell {
			border-bottom: 4px solid #fff !important;
		}
	}
}

.ant-table-tbody > tr.gray-table-row {
	&.ant-table-row:hover > td {
		background-color: unset !important;
	}

	&.gray-table-row-bg {
		background-color: lightgrey !important;
		.emma-table-action {
			background-color: lightgrey !important;
		}
	}
}

.with-opacity {
	transition: all 0.3 ease-in-out;
	&:hover {
		opacity: 0.7;
	}
}

.retracted-bid-row-bg,
.red-color {
	color: red !important;
}

.nav-tabs .nav-item {
	position: relative;
}

.green-row-bg {
	color: rgb(23, 107, 116);
	font-weight: bold;
}

.microsite-design-form-wrapper {
	.section-sub-heading {
		font-size: 20px;
	}
}

.emma-table-row-border-top {
	td {
		border-top: 2px solid var(--primary-color) !important;
		.emma-table-cell-color {
			color: var(--primary-color) !important;
		}
	}
}
.emma-table-row-border-bottom {
	td {
		border-bottom: 2px solid var(--primary-color) !important;
		.emma-table-cell-color {
			color: var(--primary-color) !important;
		}
	}
}

.virtual-panel-item:hover {
	background-color: var(--primary-color) !important;
	color: #fff !important;
	border-color: var(--primary-color) !important;
}

.ant-switch-inner .anticon {
	vertical-align: middle !important;
}
.ant-select-arrow {
	cursor: pointer !important;
}
.caret-icon {
	font-size: 20px;
	color: rgba(0, 0, 21, 0.5) !important;
	cursor: pointer !important;
}

form:not(.generic-add-form):not(.generic-edit-form),
.ant-modal-body > form,
.ant-modal.hide-native-scrollbar .ant-modal-body,
.ant-select-tree-list-holder,
.ant-popover-inner-content,
.hide-native-scrollbar {
	scrollbar-width: none; /* Firefox 64 */
	-ms-overflow-style: none; /* Internet Explorer 11 */
	&::-webkit-scrollbar {
		/** WebKit */
		display: none;
		width: 0;
		height: 0;
	}
}
.c-btn-txt-right {
	font-weight: 500;
	margin-left: 8px;
}

.no-resize,
.no-resize textarea {
	resize: none;
}

.ant-input-textarea-show-count::after {
	color: red !important;
	font-weight: 500;
}

.ant-form-item-children-icon {
	display: flex;
	justify-content: center;
	align-items: center;
}

.app-dropdown-btn.bordered {
	padding: 7px;
	border: 1px solid var(--border-color);
	border-radius: 2px;
	margin-right: 0.25rem !important;
}

.app-dropdown-btn.btn {
	background-color: #fff;
	box-shadow: none !important;
	padding: 0px;
	&:focus {
		outline: none !important;
		box-shadow: none !important;
	}
}

.the-bluebox-banner {
	width: 300px;
	background-color: transparent;
	text-align: center;
	overflow: hidden;
	visibility: hidden;
	margin-top: -70px;
	margin-left: -20px;
}

.the-bluebox-banner-elements {
	left: 0;
	top: 0;
}

.app-custom-addon-btn {
	.ant-input-group-addon {
		border: 1px solid var(--primary-color) !important;
		border-left-color: var(--primary-color) !important;
		background-color: var(--primary-color) !important;
		button {
			height: 30px;
			border: none;
			padding: 0;
			color: #fff;
		}
		button[disabled] {
			color: rgba(0, 0, 0, 0.25);
			background: #f5f5f5;
			border-color: var(--border-color);
		}
	}
}

.addon-no-padding {
	.ant-input-group-addon {
		padding: 0;
	}
}

// Override CoreUI
.c-main {
	padding-top: 1.2rem;
}
.dropdown-item {
	cursor: pointer;
}
.dropdown-item.active,
.dropdown-item:active {
	text-decoration: none;
	color: initial;
	background-color: #eee;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
	color: #fff;
	background: transparent;
}

.c-subheader-nav-link.bordered {
	max-height: 32px !important;
	padding: 7px;
	border: 1px solid var(--border-color);
	border-radius: 2px;
	margin-right: 0.25rem !important;
}

.c-subheader-nav-link.btn {
	padding-right: 0px;
	&:focus {
		outline: none !important;
		box-shadow: none !important;
	}
}

.override-label-item {
	position: absolute;
	margin-top: -23px;
}

// Color Picker

.modal-color-picker {
	width: 100% !important;
	padding: 0 !important;
	box-sizing: initial !important;
	background: rgb(255, 255, 255) !important;
	border-radius: 0 !important;
	box-shadow: none !important;

	input {
		border-radius: 2px !important;
		box-shadow: rgb(204, 204, 204) 0px 0px 0px 1px inset !important;
		&:focus {
			box-shadow: rgb(204, 204, 204) 0px 0px 0px 1px inset !important;
		}
	}
}

.table-responsive-md {
	overflow-x: scroll;
}

// Others

.bulk-confirm-modal .ant-modal-confirm-btns,
.emma-popup-confirm-modal .ant-modal-confirm-btns {
	width: 100% !important;
	float: unset !important;
	display: flex;
	justify-content: space-between;
}

.virtual-tab-panel .ant-tabs-nav {
	margin: 0 !important;
}

.modal-title-center {
	.ant-modal-title {
		text-align: center;
	}
}

.isolated-html {
	strong {
		font-weight: 700 !important;
	}
}

.summary-tab-collapse .ant-collapse-content-box {
	padding-bottom: 0 !important;
}

.isolated-html-head,
.isolated-html-simple-body {
	font-size: 13px;
	font-family: Nunito, Arial, Helvetica, Arial;
	color: #161c2d;
	font-weight: 300;
	text-align: left;
	word-break: break-word;

	// Custom

	border: 2px solid #4f5d73;
	padding: 1rem;
	margin-bottom: 1rem;
}

.pay-by-card-copy-btn {
	margin-bottom: 12px !important;
	border-left: none !important;
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	background-color: #f5f5f5 !important;
	&:hover,
	&:focus {
		border-color: var(--border-color) !important;
	}
}

div.ant-col.ant-form-item-control span.ant-select-selection-item-remove span {
	vertical-align: middle;
}

.activity-add-wrapper .ant-empty-normal {
	margin: 10px 0 !important;
}

// Drag & Drop styles

.dropzone-drag-drop-image {
	border-radius: 4px;
	background-color: #ddd;
	z-index: 99999999;
	height: 130px;
	width: 195px;
	cursor: grab !important;
}

.app-dropzone-input {
	.ant-upload.ant-upload-btn {
		padding: 2rem 0 !important;
	}

	.light[disabled],
	.light[disabled]:hover,
	.light[disabled]:focus,
	.light[disabled]:active {
		background: #b8b4b4;
		border-color: #b8b4b4;
	}
}

// React Big Calender

.rbc-toolbar-label {
	font-size: 1.7rem;
	font-weight: bold;
}

.rbc-day-slot {
	.rbc-event {
		padding: 5px !important;
		flex-direction: column-reverse !important;
	}

	// Time
	.rbc-event-label {
		margin-top: 3px !important;
		flex: 1 !important;
	}

	// Title
	.rbc-event-content {
		flex: unset !important;
		height: unset !important;
	}
}

.rbc-toolbar .rbc-btn-group button {
	background-color: var(--primary-color);
	color: #fff;

	&.rbc-active {
		background-color: #fff;
		color: var(--primary-color);
		&:hover,
		&:focus,
		&:active {
			background-color: #fff;
			color: var(--primary-color);
		}
	}
}

// Custom Toggle
.custom-toggle-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: max-content;
}

.custom-toggle-circle {
	width: 90px;
	height: 90px;
	border: 2px solid var(--border-color);
	border-radius: 50%;
	z-index: 1;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.3 ease-in-out;
	&.active {
		border-width: 3px;
		border-color: var(--primary-color);
	}
}

.custom-toggle-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 220px;
	border: 2px solid var(--border-color);
	//border-bottom-width: 4px;
	padding: 10px 20px 10px 80px;
	margin-left: -70px;
	transition: all 0.3 ease-in-out;
	border-radius: 40px;
	-webkit-border-radius: 40px;
	-moz-border-radius: 40px;
	-ms-border-radius: 40px;
	-o-border-radius: 40px;

	&.active {
		border-width: 3px;
		border-color: var(--primary-color);
	}
}

.c-sidebar-nav-dropdown-toggle {
	.anticon svg {
		transition: transform 0.3s ease;
	}
	&::after {
		display: none !important;
	}
}

td.emma-table-action.content-left-align {
	text-align: start !important;
	padding-left: 12px !important;
	padding-right: 12px !important;
}

.default_donation_amounts_row {
	.ant-input-number-handler-wrap {
		display: none;
	}
}

.layout-list-table {
	.ant-list-header {
		padding: 0 !important;
	}
	.table-header {
		padding: 8px 16px;
	}
}

.no-x-padding {
	.ant-collapse-header,
	.ant-collapse-content-box {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.emma-app-card {
	.ant-card-body {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
}

.no-increment-decrement::-webkit-inner-spin-button,
.no-increment-decrement::-webkit-outer-spin-button,
.no-increment-decrement input::-webkit-inner-spin-button,
.no-increment-decrement input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.no-increment-decrement,
.no-increment-decrement input {
	-moz-appearance: textfield;
}

.layout-planner-alert {
	max-width: fit-content;
}

.input-value-center,
.input-value-center input {
	text-align: center;
}

.emma-error-alert-card {
	padding: 15px 25px;
	background-color: #db4f4f;
	color: #fff;
	border-radius: 5px;
	p {
		margin: 0;
		text-align: center;
		font-weight: 500;
		font-size: 15px;
	}
}

.emma-success-alert-card {
	padding: 15px 25px;
	background-color: var(--primary-color);
	color: #fff;
	border-radius: 5px;
	p {
		margin: 0;
		text-align: center;
		font-weight: 500;
		font-size: 15px;
	}
}

.ant-btn.csv-btn,
.ant-btn.xls-btn,
.csv-btn,
.xls-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	.ant-btn-loading-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		.anticon {
			margin-right: 0 !important;
		}
	}
}

.ant-alert.ant-alert-info {
	background-color: #dbfbff;
	border: 1px solid #00bcd4;

	.ant-alert-icon {
		color: #00bcd4;
	}
}

.center-content-btn.ant-tooltip-disabled-compatible-wrapper button {
	display: flex;
	align-items: center;
}

.empty-ui-no-margin .ant-empty-normal {
	margin: 0 !important;
}

.dropdown-menu:not(.export-dropdown) {
	padding-top: 0 !important;
}

.dropdown-header {
	background-color: var(--primary-color);
	color: #fff;
	margin-bottom: 0.5rem;
	padding: 0.6rem 1.25rem;
	font-size: 0.9rem;
	font-weight: 600;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.group-image {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;

	.ant-image {
		height: 250px;
		width: 100%;
		max-width: 250px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	}
	.ant-image-img {
		height: 100%;
		object-fit: cover;
	}
}

.ant-modal-title {
	max-width: 95%;
}

// Context Menu NPM
.react-contextmenu {
	background-color: #fff;
	background-clip: padding-box;
	border: $ant-border;
	border-radius: 0.25rem;
	color: #373a3c;
	font-size: 16px;
	margin: 2px 0 0;
	min-width: 160px;
	outline: none;
	opacity: 0;
	padding: 5px 0;
	pointer-events: none;
	text-align: left;
	transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
	opacity: 1;
	pointer-events: auto;
	z-index: 9999;
}

.react-contextmenu-item {
	background: 0 0;
	border: 0;
	color: #373a3c;
	cursor: pointer;
	font-weight: 500;
	line-height: 1.5;
	padding: 2px 14px;
	text-align: inherit;
	white-space: nowrap;
	font-size: 14px;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
	color: #fff;
	background-color: var(--secondary-color);
	border-color: var(--secondary-color);
	text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
	background-color: transparent;
	border-color: rgba(0, 0, 0, 0.15);
	color: #878a8c;
}

.react-contextmenu-item--divider {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	cursor: inherit;
	margin-bottom: 3px;
	padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
	background-color: transparent;
	border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
	padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
	content: '▶';
	display: inline-block;
	position: absolute;
	right: 7px;
}

.example-multiple-targets::after {
	content: attr(data-count);
	display: block;
}

.warn-icon {
	color: #faad14 !important;
}

.ant-table-row-expand-icon {
	&::after,
	&::before {
		color: #fff;
	}
	border-radius: 100% !important;
	border-color: var(--primary-color) !important;
	background-color: var(--primary-color) !important;
}

.ant-form-item-control-input-content .react-tel-input {
	.form-control {
		height: 32px !important;
		width: 100% !important;
		border: $ant-border !important;
		border-radius: 2px !important;
	}

	.country-list {
		margin-top: 4px !important;
		border-radius: 2px !important;
		box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
			0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
	}
}

.common-app-error-popup {
	.ant-modal-confirm-content {
		a {
			text-decoration: underline;
			color: var(--primary-color);
		}
	}
}

.ant-image-mask-info {
	display: flex;
	align-items: center;
}

.emma-lookup-select-input .ant-select-clear {
	margin-right: 32px;
}

.ant-form-item-explain-error:has(.no-field-err-msg) {
	display: none;
}

button.v2-primary-gradient:not([disabled]) {
	--primary-color: #af2c72;
	--primary-gradient: linear-gradient(98.9deg, #af2c72 13.05%, #dd2389 50.54%, #c12877 95.44%);

	height: 48px !important;
	background: var(--primary-gradient) !important;
	border-color: var(--primary-color) !important;
	font-weight: 600 !important;
	font-size: 16px !important;
	color: #fff !important;
	&:hover,
	&:active,
	&:focus {
		color: #fff !important;
		background: var(--primary-gradient) !important;
		border-color: var(--primary-gradient) !important;
	}
}
